import { Button, CircularProgress } from "@mui/material";

export default ({ loading, children, sx, ...rest }) => {
  return (
    <Button
      disabled={loading}
      variant="contained"
      sx={{ py: 2, px: 3, ...sx }}
      {...rest}
    >
      {loading ? <CircularProgress size="1.5rem" /> : children}
    </Button>
  );
};
