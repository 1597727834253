import {
  forwardRef,
  Fragment,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import {} from "./MenuDrawer.ui";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { AuthContext } from "context/auth";
import { PageContext } from "context/page";
import BaseButton from "components/Form/BaseButton";
import { Category } from "./Menu.ui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default forwardRef(
  ({ items, openUserDialog, openPasswordDialog }, ref) => {
    const { isLogin, onLogout, onLogin, onRegister, user } =
      useContext(AuthContext);
    const { goto } = useContext(PageContext);
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          open: toggleDrawer(true),
          close: toggleDrawer(false),
        };
      },
      []
    );

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const updateUser = () => {
      handleClose();
      openUserDialog();
    };

    const changePassword = () => {
      handleClose();
      openPasswordDialog();
    };

    const logout = () => {
      handleClose();
      onLogout();
    };

    return (
      <Fragment>
        {!isLogin && (
          <Stack direction="row" gap={2}>
            <BaseButton
              variant="outlined"
              color="btnGray"
              onClick={onLogin}
              sx={{ py: 1.5 }}
            >
              Đăng nhập
            </BaseButton>
            <BaseButton onClick={onRegister} sx={{ py: 1.5 }}>
              Đăng ký
            </BaseButton>
          </Stack>
        )}

        {isLogin && (
          <Box>
            <p
              className="n pointer"
              style={{ display: "flex", justifyContent: "center" }}
              onClick={handleClick}
            >
              {user.fullName} <ExpandMoreIcon />
            </p>
            <Menu
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "var(--black3)",
                  color: "white",
                  mt: 1,
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "var(--primary70)",
                },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={updateUser}>Cập nhật thông tin</MenuItem>
              <MenuItem onClick={changePassword}>Đổi mật khẩu</MenuItem>
              <MenuItem onClick={logout}>Đăng xuất</MenuItem>
            </Menu>

            {/* <BaseButton variant="contained" onClick={onLogout}>
            Đăng xuất
          </BaseButton> */}
          </Box>
        )}

        <Stack className="up" direction="row" gap={6}>
          {items.CATEGORY.map((item) => {
            return (
              <Category
                key={item.name}
                onClick={() => goto(item.path)}
                $selected={item.selected}
              >
                {item.name}
              </Category>
            );
          })}
        </Stack>
      </Fragment>
    );
  }
);
