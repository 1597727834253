import { Stack, Drawer } from "@mui/material";
import styled from "styled-components";

export const DrawerLeft = styled(Drawer).attrs({
  anchor: "top",
})`
  .MuiPaper-root {
    background-color: var(--black);
    padding: 1.5rem 1rem;
    width: 100% !important;
    color: white;
  }
`;

export const AvatarWrapper = styled(Stack).attrs({
  flexDirection: "row",
  gap: 2,
})`
  align-items: center;
`;

export const Category = styled.p.attrs({
  className: "medium l pointer up",
})`
  &:hover {
    color: var(--primary);
  }
  ${({ $selected }) => $selected && `color: var(--primary);`}
`;

export const UserOption = styled.p.attrs({
  className: "l pointer",
})`
  &:hover {
    color: var(--primary);
  }
  ${({ $selected }) => $selected && `color: var(--primary);`}
`;

export const Logout = styled.span.attrs({
  className: "n pointer secondary up",
})`
  padding-left: 0.5rem;
`;
