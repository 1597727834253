import { Box, Stack } from "@mui/material";

import icon1 from "assets/icon/insta.svg";
import icon2 from "assets/icon/fb.svg";
import icon3 from "assets/icon/tele.svg";
import icon4 from "assets/icon/yt.svg";
import Divider from "components/Divider";
import logo from "assets/quahd/quahd.png";

export default (props) => {
  return (
    <Box className="white" sx={{ py: 1, background: "var(--black)" }}>
      <Stack sx={{ alignItems: "center", justifyContent: "center" }} gap={2}>
        <img style={{ width: "9rem" }} src={logo} />

        <Divider sx={{ width: "100%" }} />

        <Stack direction="row" gap={4} style={{ alignItems: "center" }}>
          <li>Về chúng tôi</li>
          <li>Quyền riêng tư</li>
          <li>Hỗ trợ</li>
        </Stack>

        <Stack direction="row" gap={4}>
          <img src={icon1} style={{ width: "2.5rem" }} />
          <img src={icon2} style={{ width: "2.5rem" }} />
          <img src={icon3} style={{ width: "2.5rem" }} />
          <img src={icon4} style={{ width: "2.5rem" }} />
        </Stack>

        <Divider sx={{ width: "100%" }} />

        <p>Copyright &copy; 2024 QUAHD. All rights reserved</p>
      </Stack>
    </Box>
  );
};
