import { Box, Dialog } from "@mui/material";
import { ResizeContext } from "context/resize";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";

export default forwardRef(({ children, onOpen, closeable, paperProp }, ref) => {
  const { isMobile } = useContext(ResizeContext);

  const [open, setOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: handleClickOpen,
        close: handleClose,
      };
    },
    []
  );

  const handleClickOpen = (e) => {
    onOpen?.(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeable && handleClose}
      maxWidth="false"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: isMobile ? "90vw" : null,
          margin: isMobile ? 0 : null,
          maxWidth: "35rem",
          ...paperProp,
        },
      }}
    >
      <Box
        className="white"
        sx={{
          backgroundColor: "#323232",
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>{children}</Box>
      </Box>
    </Dialog>
  );
});
