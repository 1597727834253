import axios from "axios";
import { DB } from "utils/constants";
import { getItem } from "utils/localStorage";
import { ACCESS_TOKEN } from "context/auth";
import { e500, noti } from "utils/helper";

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
    };
    return config;
  },
  function (error) {
    console.log("request error");
    return null;
  }
);

axios.interceptors.response.use(
  function (response) {
    return response?.data;
  },
  function (error) {
    if (error?.config?.url?.indexOf("SyncDuration") !== -1) return;

    const message = error?.response?.data?.message || error?.response?.data;

    if (error.status === 401) {
    } else if (message && typeof message === "string") {
      noti(message, "error");
    } else e500();
    return error.response;
  }
);

export const call = async (method, url, data, headers = {}) => {
  try {
    const options = {
      method,
      url: DB + url,
      data,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    };

    const token = getItem(ACCESS_TOKEN);
    if (token) options.headers["Authorization"] = "Bearer " + token;

    return await axios(options);
  } catch (error) {
    console.log("call error", error);
  }
};

export const callAuth = async (
  method,
  url,
  data = {},
  headers = {},
  setProgress
) => {
  try {
    const token = getItem(ACCESS_TOKEN);

    if (!token) {
      return noti("Bạn cần đăng nhập!", "warning");
    }

    return await axios({
      method,
      url: DB + url,
      data,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        ...headers,
      },
      onUploadProgress: (data) => {
        setProgress?.(Math.round((100 * data.loaded) / data.total));
      },
    });
  } catch (error) {
    console.log("callAuth error", error);
  }
};
