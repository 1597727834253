import { createTheme } from "@mui/material/styles";
const { palette } = createTheme();

const cssVar = (name) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

const calcFontSize = (expectedBodyFontSize) => {
  return (14 / 16) * expectedBodyFontSize;
};

export const themeOptions = {
  spacing: (factor) => `${0.5 * factor}rem`,
  gap: (factor) => `${0.5 * factor}rem`,
  typography: {
    fontFamily: cssVar("--font"),
    button: {
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: `0.5rem`,
  },
};

export const paletteMain = {
  primary: {
    main: cssVar("--primary"),
    contrastText: "#fff",
  },
  secondary: {
    main: cssVar("--secondary"),
    contrastText: "#fff",
  },
  info: {
    main: cssVar("--info"),
  },
  success: {
    main: cssVar("--success"),
  },
  warning: {
    main: cssVar("--warning"),
  },
  error: {
    main: cssVar("--error"),
  },
  iprimary: palette.augmentColor({
    color: {
      main: "#fff",
      contrastText: cssVar("--primary"),
    },
  }),
  google: palette.augmentColor({
    color: {
      main: "#fff",
      contrastText: "#000",
    },
  }),
  btnGray: palette.augmentColor({
    color: {
      main: "#e8e8e8",
      contrastText: "#e8e8e8",
    },
  }),
  btnWhite: palette.augmentColor({
    color: {
      main: "#fff",
    },
  }),
};

export default createTheme({ ...themeOptions, palette: paletteMain });
