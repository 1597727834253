import { Outlet } from "react-router";
import Header from "components/LayoutAuth/Header";
import Footer from "components/LayoutAuth/Footer";
import { PageLayout } from "./index.ui";

export default () => {
  return (
    <PageLayout>
      <Header />
      <Outlet />
      <Footer />
    </PageLayout>
  );
};
