import styled from "styled-components";

export const Text = styled.p`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export const TextSpan = styled.span`
  color: #ed4337;
  margin-left: 0.2rem;
  font-weight: normal;
`;
