import {
  forwardRef,
  Fragment,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import {
  AvatarWrapper,
  Category,
  DrawerLeft,
  Logout,
  UserOption,
} from "./MenuDrawer.ui";
import { Grid2, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "components/Divider";
import { AuthContext } from "context/auth";
import { PageContext } from "context/page";
import BaseButton from "components/Form/BaseButton";

export default forwardRef(
  ({ items, openUserDialog, openPasswordDialog }, ref) => {
    const { isLogin, onLogout, onLogin, onRegister, user } =
      useContext(AuthContext);
    const { goto } = useContext(PageContext);
    const [open, setOpen] = useState();

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          open: toggleDrawer(true),
          close: toggleDrawer(false),
        };
      },
      []
    );

    const updateUser = () => {
      toggleDrawer(false)();
      openUserDialog();
    };

    const changePassword = () => {
      toggleDrawer(false)();
      openPasswordDialog();
    };

    return (
      <DrawerLeft open={open} onClose={toggleDrawer(false)}>
        <Stack gap={2}>
          <Stack
            gap={2}
            flexDirection="row"
            style={{ justifyContent: "flex-end" }}
          >
            <CloseIcon className="pointer" onClick={toggleDrawer(false)} />
          </Stack>

          {isLogin && (
            <AvatarWrapper>
              {/* <Avatar sizes="small" sx={{ width: 44, height: 44 }} /> */}
              <p className="l">
                Xin chào
                <span className="up medium">
                  {user.fullName ? ", " + user.fullName : ""}
                </span>
                {/* <Logout onClick={onLogout}>(Đăng xuất)</Logout> */}
              </p>
            </AvatarWrapper>
          )}

          {!isLogin && (
            <Grid2 container spacing={2}>
              <Grid2 size={6}>
                <BaseButton
                  variant="outlined"
                  color="btnGray"
                  onClick={() => {
                    toggleDrawer(false)();
                    onLogin();
                  }}
                  sx={{ width: "100%" }}
                >
                  Đăng nhập
                </BaseButton>
              </Grid2>

              <Grid2 size={6}>
                <BaseButton
                  onClick={() => {
                    toggleDrawer(false)();
                    onRegister();
                  }}
                  sx={{ width: "100%" }}
                >
                  Đăng ký
                </BaseButton>
              </Grid2>
            </Grid2>
          )}

          <Divider />

          {items.CATEGORY.map((item) => {
            return (
              <Category
                key={item.name}
                onClick={() => goto(item.path)}
                $selected={item.selected}
              >
                {item.name}
              </Category>
            );
          })}

          <Divider />

          {isLogin && (
            <Fragment>
              <UserOption onClick={updateUser}>Cập nhật thông tin</UserOption>
              <UserOption onClick={changePassword}>Đổi mật khẩu</UserOption>
              <UserOption onClick={onLogout}>Đăng xuất</UserOption>
            </Fragment>
          )}
        </Stack>
      </DrawerLeft>
    );
  }
);
