import Content from "shared/Content";
import { e500, noti, onResult, validateMail } from "utils/helper";
import { useForm } from "react-hook-form";
import { forwardRef, Fragment, useContext, useState } from "react";
import BaseInput from "components/Form/BaseInput";
import InputPassword from "components/Form/InputPassword";
import { AuthContext } from "context/auth";
import BaseButton from "components/Form/BaseButton";
import { GG } from "../Register";
import Dialog from "components/Dialog";
import { authForgetPassword, authLogin } from "api/auth";
import { Box, Checkbox, Grid2, Stack } from "@mui/material";
import { Header, Wrapper } from "./index.ui";
import { LoadingContext } from "context/loading";

export const LoginDialog = forwardRef(({}, ref) => {
  const { onLoading, onFinish  } = useContext(LoadingContext);
  const { onLoginSuccess } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [forget, setForget] = useState(false);

  const {
    getValues,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendPassword = async () => {
    const values = getValues();

    const email = values.email?.trim();
    if (!validateMail(email)) {
      noti("Email không hợp lệ!", "error");
      return;
    }

    // setLoading(true)
    onLoading("changePassword")
    const result = await authForgetPassword({
      email,
    });

    if (onResult(result)) {
      noti("Thông tin đã được gửi đến email của bạn!", "success");
      // onLoginSuccess(result);
      // ref.current.close();
    }

    onFinish("changePassword")
    // setLoading(false)
  };

  const onSubmit = async ({ email, password }, e) => {
    e.preventDefault();

    if (!validateMail(email)) {
      noti("Email không hợp lệ!", "error");
      return;
    }

    if (!password || password.length < 8) {
      return noti("Mật khẩu không hợp lệ", "error");
    }

    setLoading(true);

    const result = await authLogin({
      username: email,
      password,
    });

    if (result?.access_token) {
      onLoginSuccess(result);
      ref.current.close();
    }

    setLoading(false);
  };

  const onOpen = () => {
    setForget(false);
    reset();
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <Dialog ref={ref} onOpen={onOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Wrapper className="white">
          <Stack gap={2}>
            <Header>Đăng nhập</Header>
            <BaseInput
              title="Email"
              form={register("email")}
              placeholder="Nhập email"
            />
            {!forget && (
              <InputPassword
                title="Mật khẩu"
                form={register("password")}
                placeholder="Nhập mật khẩu"
              />
            )}

            <p className="primary" style={{ textAlign: "end" }}>
              {!forget ? (
                <span className="pointer" onClick={() => setForget(true)}>
                  Quên mật khẩu?
                </span>
              ) : (
                <span className="pointer" onClick={() => setForget(false)}>
                  Quay lại đăng nhập
                </span>
              )}
            </p>
            {!forget && (
              <Fragment>
                <BaseButton type="submit" variant="contained" loading={loading}>
                  Đăng nhập
                </BaseButton>
                {/* <GG /> */}
              </Fragment>
            )}
            {forget && (
              <Fragment>
                <BaseButton
                  onClick={sendPassword}
                  variant="contained"
                  loading={loading}
                >
                  Đổi mật khẩu
                </BaseButton>
              </Fragment>
            )}
            <BaseButton color="btnWhite" onClick={() => ref.current.close()}>
              Đóng
            </BaseButton>
          </Stack>
        </Wrapper>
      </form>
    </Dialog>
  );
});
