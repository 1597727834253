import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "utils/theme";
import { SnackbarProvider } from "notistack";
import Router from "router";
import { PageProvider } from "context/page";
import { AuthProvider } from "context/auth";
import { ResizeProvider } from "context/resize";
import { LoadingProvider } from "context/loading";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter
            future={{
              v7_startTransition: true,
            }}
          >
            <LoadingProvider>
              <ResizeProvider>
                <PageProvider>
                  <AuthProvider>
                    <Router />
                  </AuthProvider>
                </PageProvider>
              </ResizeProvider>
            </LoadingProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
