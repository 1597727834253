const { default: styled } = require("styled-components");

export const Header = styled.h4`
  align-self: center;
`;

export const Wrapper = styled.div`
  background-color: "#323232";

  @media screen and (min-width: 1000px) {
    min-width: 30rem;
  }
`;
