export const DB = decodeURI(process.env.REACT_APP_DB).trim() + "/";

export const POST = "post";
export const PUT = "put";
export const GET = "get";
export const DELETE = "delete";

export const COURSE = {
  TYPE: {
    BASIC: "Cơ bản",
    ADVANCE: "Nâng cao",
  },
  TAG: {
    RECENT: "Khóa học mới nhất",
    NEWBIE: "Khóa học cho người mới bắt đầu",
    MIDDLE: "Khóa học trung cấp",
    ADVANCE: "Khóa học nâng cao",
  },
};

export const CLIENT_ID =
  "248606734247-mi6n1gksh24ks6o648mbeu5vstkql5ub.apps.googleusercontent.com";
