import { OutlinedInput } from "@mui/material";
import BaseTitle from "./BaseTitle";

export default ({ required, title, form, errorMessage, sx, ...rest }) => {
  let _sx = {
    borderRadius: 1,
    input: { color: "white" },
    MuiSelect: { root: { "&.MuiFilledInput-input": { color: "white" } } },
    width: "100%",
  };

  const color = "rgba(255,255,255,0.1)";

  _sx = {
    ..._sx,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: color,
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary)",
    },
  };

  return (
    <div>
      <BaseTitle required={required}>
        {title}
      </BaseTitle>
      <OutlinedInput sx={{ ..._sx, ...sx }} {...rest} {...form} />
    </div>
  );
};
