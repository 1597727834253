import { createContext, useEffect, useState } from "react";

export const ResizeContext = createContext();

export const ResizeProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <ResizeContext.Provider value={{ isMobile }}>
      {children}
    </ResizeContext.Provider>
  );
};
