import { forwardRef, useContext, useState } from "react";
import { noti, onResult, validateMail } from "utils/helper";
import { useForm } from "react-hook-form";
import BaseInput from "components/Form/BaseInput";
import InputPassword from "components/Form/InputPassword";
import BaseButton from "components/Form/BaseButton";
import gg from "assets/gg.svg";
import Dialog from "components/Dialog";
import { Stack } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { Header, Wrapper } from "./index.ui";
import { authRegister } from "api/auth";

export const RegisterDialog = forwardRef(({}, ref) => {
  const [loading, setLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (
    { fullName, phoneNumber, email, gender, userName, password, repassword },
    e
  ) => {
    e.preventDefault();

    if (!validateMail(email)) {
      noti("Email không hợp lệ!", "error");
      return;
    }

    if (!password || password.length < 8) {
      return noti("Mật khẩu cần ít nhất 8 ký tự", "error");
    }

    if (password != repassword) {
      return noti("Mật khẩu không hợp lệ", "error");
    }

    setLoading(true);

    const result = await authRegister({
      fullName,
      email,
      phoneNumber,
      // gender: !!+gender,
      userName: email,
      // dateOfBirth: new Date().toISOString(),
      password,
      confirmPassword: repassword,
    });

    if (onResult(result)) {
      noti("Đăng ký thành công, vui lòng kiểm tra email!", "success");
      ref.current.close();
      reset();
    }

    setLoading(false);
  };

  return (
    <Dialog ref={ref} onOpen={reset}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Wrapper className="white">
          <Stack gap={2}>
            <Header>Đăng ký</Header>

            <BaseInput
              required
              title="Họ và tên"
              form={register("fullName")}
              placeholder="Nhập họ và tên"
            />
            <BaseInput
              required
              title="Địa chỉ email"
              form={register("email")}
              placeholder="Nhập địa chỉ email"
            />
            <BaseInput
              required
              title="Số điện thoại"
              form={register("phoneNumber")}
              placeholder="Nhập số điện thoại"
            />
            <InputPassword
              required
              title="Mật khẩu"
              form={register("password")}
              placeholder="Nhập mật khẩu"
            />
            <InputPassword
              required
              title="Nhập lại mật khẩu"
              form={register("repassword")}
              placeholder="Nhập lại mật khẩu"
            />

            <BaseButton type="submit" sx={{ mt: 1 }} loading={loading}>
              Đăng ký
            </BaseButton>
            {/* <GG /> */}
            <BaseButton color="btnWhite" onClick={() => ref.current.close()}>
              Đóng
            </BaseButton>
          </Stack>
        </Wrapper>
      </form>
    </Dialog>
  );
});

export const GG = () => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(tokenResponse),
  });

  return (
    <BaseButton
      color="google"
      sx={{
        justifyContent: "center",
      }}
      onClick={() => login()}
    >
      <img src={gg} style={{ margin: "0 1rem", width: "1.5rem" }} />
      <span style={{ marginTop: "1px" }}>Đăng nhập bằng tài khoản Google</span>
    </BaseButton>
  );
};
