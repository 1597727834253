import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGES } from "router";

export const PageContext = createContext();

export const PageProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [page, setPage] = useState();

  useEffect(() => {
    const path = pathname.split("?")[0].slice(1);
    setPage(path || PAGES.DEFAULT);
  }, [pathname]);

  const goto = (path, options) => {
    navigate(`/${path}`, options);
  };

  return (
    <PageContext.Provider value={{ page, goto }}>
      {children}
    </PageContext.Provider>
  );
};
