import { useState } from "react";

import BaseInput from "./BaseInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default (props) => {
  const [type, setType] = useState();

  const toggle = () => setType(!type);

  return (
    <BaseInput
      type={type ? "text" : "password"}
      endAdornment={
        type ? (
          <VisibilityIcon
            className="pointer"
            sx={{ width: "2rem", color: "#fff" }}
            onClick={toggle}
          />
        ) : (
          <VisibilityOffIcon
            className="pointer"
            sx={{ width: "2rem", color: "#fff" }}
            onClick={toggle}
          />
        )
      }
      {...props}
    />
  );
};
