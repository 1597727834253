import { Text, TextSpan } from "./BaseTitle.ui";

export default ({ required, children }) => {
  if (!children) return null;
  return (
    <Text>
      {children}
      <TextSpan>{required && "*"}</TextSpan>
    </Text>
  );
};
