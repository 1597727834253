import { lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";
import loadable from "@loadable/component";
import LayoutAuth from "components/LayoutAuth";
import Layout from "components/Layout";

const Course = loadable(() => import("module/Course"));
const Detail = loadable(() => import("module/Course/Detail"));
const Lesson = loadable(() => import("module/Course/Lesson"));
const AccountActive = loadable(() => import("module/AccountActive"));
const AccountUpdatePassword = loadable(() =>
  import("module/AccountUpdatePassword")
);

export const PAGES = {
  DEFAULT: "course",
  COURSE: "course",
  COURSE_LESSONS: "lessons",

  ACCOUNT_ACTIVE: "kich-hoat-tai-khoan",
  ACCOUNT_UPDATE_PASSWORD: "dat-lai-mat-khau",
};

export const COURSE = {
  COURSE_DETAIL: `${PAGES.COURSE}/:id`,
  COURSE_LESSONS: `${PAGES.COURSE}/${PAGES.COURSE_LESSONS}/:id`,
};

export default () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route element={<LayoutAuth />}>
        <Route index element={<Course />} />
        <Route path={PAGES.COURSE} element={<Course />} />
        <Route path={COURSE.COURSE_DETAIL} element={<Detail />} />
        <Route path={COURSE.COURSE_LESSONS} element={<Lesson />} />
      </Route>

      <Route element={<Layout />}>
        <Route path={PAGES.ACCOUNT_ACTIVE} element={<AccountActive />} />
        <Route
          path={PAGES.ACCOUNT_UPDATE_PASSWORD}
          element={<AccountUpdatePassword />}
        />
      </Route>
    </Routes>
  );
};
