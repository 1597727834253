import Content from "shared/Content";
import { e500, noti, onResult, validateMail } from "utils/helper";
import { useForm } from "react-hook-form";
import { forwardRef, Fragment, useContext, useEffect, useState } from "react";
import BaseInput from "components/Form/BaseInput";
import InputPassword from "components/Form/InputPassword";
import { AuthContext } from "context/auth";
import BaseButton from "components/Form/BaseButton";
import { GG } from "../Register";
import Dialog from "components/Dialog";
import { authForgetPassword, authLogin } from "api/auth";
import { Box, Grid2, Stack } from "@mui/material";
import { Header, Wrapper } from "./index.ui";
import { changePassword, updateUser } from "api/user";

export const UpdateUserDialog = forwardRef(({}, ref) => {
  const [loading, setLoading] = useState(false);

  const { user, getUserData, api } = useContext(AuthContext);
  const {
    getValues,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(user);
  }, [user]);

  const onSubmit = async ({ fullName, phoneNumber }, e) => {
    e.preventDefault();
    setLoading(true);

    const result = await api(updateUser, {
      fullName,
      phoneNumber,
    });

    if (onResult(result)) {
      noti("Cập nhật thành công!", "success");
      getUserData();
      ref.current.close();
    }

    setLoading(false);
  };

  const onOpen = () => {
    reset();
    getUserData();
  };

  return (
    <Dialog ref={ref} onOpen={onOpen}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Wrapper className="white">
          <Stack gap={2}>
            <Header>Cập nhật thông tin</Header>
            <BaseInput
              readOnly
              title="Email"
              form={register("email")}
              // placeholder="Nhập họ và tên"
            />

            <BaseInput
              title="Họ và tên"
              form={register("fullName")}
              placeholder="Nhập họ và tên"
            />

            <BaseInput
              title="Số điện thoại"
              form={register("phoneNumber")}
              placeholder="Nhập số điện thoại"
            />

            <BaseButton
              sx={{ mt: 1 }}
              type="submit"
              variant="contained"
              loading={loading}
            >
              Cập nhật
            </BaseButton>

            <BaseButton color="btnWhite" onClick={() => ref.current.close()}>
              Đóng
            </BaseButton>
          </Stack>
        </Wrapper>
      </form>
    </Dialog>
  );
});
