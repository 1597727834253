import Content from "shared/Content";
import { e500, noti, onResult, validateMail } from "utils/helper";
import { useForm } from "react-hook-form";
import { forwardRef, Fragment, useContext, useEffect, useState } from "react";
import BaseInput from "components/Form/BaseInput";
import InputPassword from "components/Form/InputPassword";
import { AuthContext } from "context/auth";
import BaseButton from "components/Form/BaseButton";
import { GG } from "../Register";
import Dialog from "components/Dialog";
import { authForgetPassword, authLogin } from "api/auth";
import { Box, Grid2, Stack } from "@mui/material";
import { Header, Wrapper } from "./index.ui";
import { changePassword } from "api/user";

export const ChangePasswordDialog = forwardRef(({}, ref) => {
  const { api } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {
    getValues,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (
    { currentPassword, newPassword, confirmPassword },
    e
  ) => {
    e.preventDefault();

    if (!newPassword || newPassword.length < 8) {
      return noti("Mật khẩu mới không hợp lệ", "error");
    }

    if (newPassword !== confirmPassword) {
      return noti("Mật khẩu không hợp lệ", "error");
    }

    setLoading(true);

    const result = await api(changePassword, {
      currentPassword,
      newPassword,
      confirmPassword,
    });

    if (onResult(result)) {
      noti("Cập nhật thành công!", "success");
      ref.current.close();
    }

    setLoading(false);
  };

  const onOpen = async () => {
    reset();
  };

  return (
    <Dialog ref={ref} onOpen={onOpen}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Wrapper className="white">
          <Stack gap={2}>
            <Header>Đổi mật khẩu</Header>
            <InputPassword
              title="Mật khẩu hiện tại"
              form={register("currentPassword")}
              placeholder="Nhập mật khẩu hiện tại"
            />

            <InputPassword
              title="Mật khẩu mới"
              form={register("newPassword")}
              placeholder="Nhập mật khẩu mới"
            />

            <InputPassword
              title="Nhập lại mật khẩu"
              form={register("confirmPassword")}
              placeholder="Nhập lại mật khẩu"
            />

            <BaseButton
              sx={{ mt: 1 }}
              type="submit"
              variant="contained"
              loading={loading}
            >
              Đổi mật khẩu
            </BaseButton>

            <BaseButton color="btnWhite" onClick={() => ref.current.close()}>
              Đóng
            </BaseButton>
          </Stack>
        </Wrapper>
      </form>
    </Dialog>
  );
});
