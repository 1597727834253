export const ACCCESS = "ACCCESS";

export const LESSON_TIMESTAMP = "LAST_VIDEO";

export const updateItem = (key, data) => {
  const val = localStorage.getItem(LESSON_TIMESTAMP);
  const obj = val ? JSON.parse(val) : {};
  localStorage.setItem(key, JSON.stringify({ ...obj, data }));
};

export const setItem = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
};

export const getItem = (key) => {
  const val = localStorage.getItem(key);
  if (val) return JSON.parse(val);
  return val;
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const clearAll = (key) => {
  localStorage.clear();
};
