import styled from "styled-components";

export const MenuWrapper = styled.div`
  display: none;

  @media screen and (min-width: 1000px) {
    display: block;
  }
`;

export const Category = styled.p.attrs({
  className: "medium pointer",
})`
  &:hover {
    color: var(--primary);
  }
  ${({ $selected }) => $selected && `color: var(--primary);`}
`;
