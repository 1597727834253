import { Box } from "@mui/material";
import styled, { css } from "styled-components";

const SPACE = {
  m: "margin",
  p: "padding",
  br: "border-radius",

  r: "right",
  l: "left",
  t: "top",
  b: "bottom",
};

const space = css`
  ${({ $space }) =>
    $space &&
    $space
      .split(" ")
      .map((className) => {
        const list = className.split("-");
        if (list.length === 3) {
          const [f, mid, num] = list;

          switch (mid) {
            case "x":
              return `
                ${SPACE[f]}-left: ${num}rem;
                ${SPACE[f]}-right: ${num}rem;
              `;
            case "y":
              return `
                ${SPACE[f]}-top: ${num}rem;
                ${SPACE[f]}-bottom: ${num}rem;
              `;
            default:
              return `${SPACE[f]}-${SPACE[mid]}: ${num}rem;`;
          }
        }
        if (list.length === 2) {
          const [f, num] = list;
          return `${SPACE[f]}: ${num}rem;`;
        }
      })
      .join("")}
`;

export const cs = css`
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $bg }) => $bg && `background-color: ${$bg};`}
  ${({ $bgi }) => $bgi && `background-image: ${$bgi};`}

  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $width }) => $width && `width: ${$width};`}
  
  ${({ $border }) => $border && `border: ${$border};`}
  ${({ $borderBottom }) => $borderBottom && `border: ${$borderBottom};`}
  
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  ${({ $padding }) => $padding && `padding: ${$padding};`}

  ${({ $justify }) => $justify && `justify-content: ${$justify};`}
  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $self }) => $self && `align-self: ${$self};`}
  ${({ $text }) => $text && `text-align: ${$text};`}

  ${({ $position }) => $position && `position: ${$position};`}
  ${({ $objFit }) => $objFit && `object-fit: ${$objFit};`}
  ${({ $object }) => $object && `object-position: ${$object};`}

  ${({ $z }) => $z && `z-index: ${$z};`}
  
  ${space}
  ${({ $css }) => $css}

  ${({ $hover }) =>
    $hover &&
    `
      &:hover {
        ${$hover}
      }
    `}
`;

const Content = styled(Box)`
  ${cs}
`;

export default Content;

// export default ({ $t = Box, ...rest }) => {
//   let B;

//   if (styled[$t]) {
//     B = styled[$t]`
//       ${cs}
//     `;
//   } else {
//     B = styled($t)`
//       ${cs}
//     `;
//   }

//   return <B {...rest} />;
// };
